<template>
	<div class="big-label">
		{{ text }}
	</div>
</template>

<script>
export default {
	name: 'BigLabel',
	props: {
		text: { type: String },
	},
}
</script>

<style scoped lang="sass">
.big-label
	font-size: 32px
	font-size: 1.5em
	font-weight: 700
	// padding: 4px 16px 4px 0px
	color: black
	opacity: 1
	letter-spacing: -0.02em
</style>