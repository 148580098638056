<template>
	<v-row align="center" id="top-bar-row" no-gutters>
		<v-col cols="3" class="fill-height d-flex align-center title-bar" no-gutters>
			<v-col class="logo fill-height d-flex align-center justify-center border-black pa-2">
				<v-btn
					text
					color="white"
					fill-height
					class="logo-wrapper d-flex align-center justify-center"
					:to="{ name: 'configurator-start' }"
				>
					<Logo />
				</v-btn>
			</v-col>
		</v-col>
		<v-col cols="6">
			<StepNavigation
				v-if="currentStep === 'textile' || currentStep === 'motive' || currentStep === 'variants' || currentStep === 'sizes'"
			/>
		</v-col>
		<v-col cols="3" class="fill-height d-flex align-center justify-end pl-5">
			<!-- <v-btn
				elevation="0"
				depressed
				outlined
				tile
				class="mx-3 font-weight-bold"
				style="opacity: 0.4"
				small
				@click="resetEverything"
				v-if="showResetBtn && environment === 'development'"
				>Reset All</v-btn
			>
			<v-btn
				elevation="0"
				depressed
				outlined
				tile
				class="mx-3 font-weight-bold"
				style="opacity: 0.4"
				small
				@click="resetConfig"
				v-if="showResetBtn && environment === 'development'"
				>Reset</v-btn
			>

			<v-btn text class="mx-3" v-if="showNewConfigBtn" @click="newConfig">{{
				$getDictString('configurator_topbar_heading_main')
			}}</v-btn> -->
			<v-col
				class="corner-right fill-height d-flex align-center justify-center border-black border-solid border-l-2 p-0"
				v-if="showOverviewBtn"
			>
				<router-link :to="{ name: 'configurator-overview' }" v-if="showOverviewBtn">
					<button class="overview-btn fill-height d-flex align-center justify-center">
						{{ $getDictString('configurator_topbar_button_cart') }} <v-icon large dark> mdi-chevron-right </v-icon>
					</button>
				</router-link>
			</v-col>
			<!-- <button class="login">{{ $getDictString('configurator_topbar_button_login') }}</button> -->
		</v-col>
	</v-row>
</template>

<script>
import Logo from './Logo'
import StepNavigation from '../components/StepNavigation'
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'TopBar',
	components: {
		Logo,
		StepNavigation,
	},
	props: {
		title: { type: String, default: 'Neue Anfrage' },
		showOverviewBtn: { type: Boolean, default: true },
		showResetBtn: { type: Boolean, default: true },
		showNewConfigBtn: { type: Boolean, default: false },
	},
	computed: {
		...mapFields('ConfiguratorDisplay', {
			currentStep: 'currentStep',
		}),
	},
	data() {
		return {
			environment: process.env.NODE_ENV,
		}
	},
	methods: {
		resetConfig() {
			this.$store.dispatch('ConfiguratorDisplay/setStep', 'textile').then(() => {
				this.$store.dispatch('Configurator/resetConfiguration')
			})
		},
		resetEverything() {
			this.$store.dispatch('ConfiguratorDisplay/setStep', 'textile').then(() => {
				this.$store.dispatch('Configurator/resetEverything')
			})
		},
		newConfig() {
			this.$store.dispatch('Configurator/resetConfiguration')
			this.$router.push({ name: 'configurator-config' })
		},
	},
}
</script>

<style scoped lang="sass">
.logo
	max-width: 250px
	position: relative
	top: -2px
.corner-right
	max-width: 200px

#top-bar-row
	border-bottom: 2px solid black
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 60px
	z-index: 201
	background: white
.logo-wrapper
	width: 80%
	height: 80%
	background: white
.login
	background: transparent
	font-weight: 700
	font-size: 1.8em
	letter-spacing: -.05em
	width: 100px
	height: 100%
	color: black
	&:focus
		outline: none

.title-bar
	font-size: 2em
	font-weight: 700
	letter-spacing: -.05em

.corner-right
	a
		height: 100%
		width: 100%

.overview-btn
	border: none
	background: black
	color: white
	width: 100%
	height: 100%
	padding: 0
	margin: 0
	font-size: 1.5em
	font-weight: 700
	letter-spacing: -.02em
	outline: none
</style>