<template>
	<div class="step-navigation-wrapper steps-container d-flex justify-center items-center">
		<div class="step-navigation" ref="step-navigation">
			<span
				v-for="(step, idx) in steps"
				class="d-flex justify-center items-center"
				:key="idx"
				@click="changeStep(step.key)"
				:class="isActive(step.key) && 'active'"
				:ref="step.key + '-btn'"
			>
				<div
					v-if="step.key == 'motive' && Object.keys(finishes).length > 0"
					:class="isActive(step.key) && 'active'"
					class="counter"
				>
					{{ Object.keys(finishes).length }}
				</div>
				<div
					v-if="step.key == 'variants' && Object.keys(variants).length > 0"
					:class="isActive(step.key) && 'active'"
					class="counter"
				>
					{{ Object.keys(variants).length }}
				</div>
				{{ step.value }}
			</span>
		</div>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'StepNavigation',
	computed: {
		...mapFields('ConfiguratorDisplay', {
			currentStep: 'currentStep',
		}),
		...mapFields('Configurator', {
			variants: 'variants',
			finishes: 'finishes',
		}),
		steps() {
			return [
				{ key: 'textile', value: this.$getDictString('configurator_textile_big_heading') },
				{ key: 'motive', value: this.$getDictString('configurator_finish_big_heading') },
				{ key: 'variants', value: this.$getDictString('configurator_variants_big_heading') },
				{ key: 'sizes', value: this.$getDictString('configurator_amount_big_heading') },
			]
		},
	},
	methods: {
		isActive(type) {
			return type === this.currentStep
		},
		changeStep(type) {
			this.$store.dispatch('ConfiguratorDisplay/setStep', type)
			// this.$refs.scrollContainer.scrollTo({ top: 0, behavior: 'smooth' })
			document.getElementById('configurator-wrapper-scroll-container').scrollTo({ top: 0, behavior: 'smooth' })
		},
	},
}
</script>

<style lang="sass" scoped>
.step-navigation-wrapper
    // position: fixed
    z-index: 11111111
    // left: 0px
    width: 100%
    bottom: 10px
    display: inline-flex
    height: 100%
    @media screen and (max-width: 900px)
        display: none !important

    .step-navigation-indicator
        width: 25%
        height: 2px
        background: black
        background: rgba(70,70,70,0.1)

        // position: relative
        // top: 46px

    .step-navigation
        background: white
        font-size: 1.4em
        font-weight: 700
        letter-spacing: -.02em
        padding: 0px 0px
        // position: absolute
        transition: left 400ms
        display: flex !important
        justify-content: center
        align-items: space-around

        .counter
            background: black
            color: white
            text-align: center
            display: inline-flex
            line-height: 1
            width: 1.2em
            height: 1.2em
            justify-content: center
            align-items: center
            border-radius: 50%
            margin-right: 2px
            opacity: 0.2
            &.active
                opacity: 1
        span
            display: inline-block
            color: rgba(0,0,0,0.3)
            padding: 10px 15px
            cursor: pointer
            transition: color 400ms
            &:hover
                color: rgba(0,0,0,0.9)
                .counter
                    opacity: 1
            &.active
                color: black

.steps-container
    height: 58px
    // position: fixed
    // width: 33%
    // bottom: 40px
    // left: -2px
    padding: 20px 30px
    z-index: 1111
    background: white
    // border-top: 1px solid rgba(70,70,70,0.1)
    // border-right: 1px solid rgba(70,70,70,0.1)
    // border: 1px solid rgba(210,210,210,0.8)
    // z-index: 11111111
    z-index: 200
    @media screen and (max-width: 900px)
        // opacity: 0
</style>