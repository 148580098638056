<template>
	<div class="product-wrapper d-flex flex-column justify-start border-bottom">
		<div v-if="cartOverlay" class="overlay">
			<RemoveConfigurationWarningModal :editId="configuration.editId" />
			<div class="edit-btn d-flex align-center justify-center" @click="editConfiguration(configuration)">
				{{ $getDictString('configurator_cart_button_edit_config') }}
			</div>
		</div>
		<div class="index d-flex align-center justify-center">{{ idx + 1 }}</div>

		<div class="product-inner fill-height d-flex justify-start" v-for="(variant, id) in configuration.variants" :key="id">
			<div class="image d-flex align-center flex-column justify-center" :class="{ cap: configuration.ref.productType == 'cap' }">
				<SVGFront
					class="svg-image"
					mockupSide="front"
					:mainColor="variant.extractedHexColor"
					:secColor="variant.extractedHexColor"
					:allFinishes="configuration.finishes"
					currentStep="cart"
					v-if="visibleMockupSide === 'front'"
					:mockupType="configuration.ref.productType"
					:hideBlockedAreas="true"
					:filesAlt="files"
				/>

				<SVGBack
					class="svg-image"
					mockupSide="back"
					:mainColor="variant.extractedHexColor"
					:secColor="variant.extractedHexColor"
					:allFinishes="configuration.finishes"
					currentStep="cart"
					v-if="visibleMockupSide === 'back'"
					:mockupType="configuration.ref.productType"
					:filesAlt="files"
				/>
				<SVGLeft
					class="svg-image"
					mockupSide="left"
					:mainColor="variant.extractedHexColor"
					:secColor="variant.extractedHexColor"
					:allFinishes="configuration.finishes"
					currentStep="cart"
					v-if="
						visibleMockupSide === 'left' &&
						(configuration.finishes[0].position === 'shoulder-sleeve-left-custom' ||
							configuration.finishes[0].position === 'custom-left')
					"
					:mockupType="configuration.ref.productType"
					:filesAlt="files"
				/>
				<SVGRight
					class="svg-image"
					mockupSide="right"
					:mainColor="variant.extractedHexColor"
					:secColor="variant.extractedHexColor"
					:allFinishes="configuration.finishes"
					currentStep="cart"
					v-if="
						visibleMockupSide === 'right' &&
						(configuration.finishes[0].position === 'shoulder-sleeve-right-custom' ||
							configuration.finishes[0].position === 'custom-right')
					"
					:mockupType="configuration.ref.productType"
					:filesAlt="files"
				/>
				<small class="variant-color text-center"
					>Textilfarbe:<br />
					<b>{{ variant.color }}</b></small
				>
			</div>
			<div class="product-info">
				<div class="top">
					<div class="amount d-flex align-center">
						{{ getTotalAmount(variant) }}<img src="~@/assets/icons/cross-icon.png" class="ml-1" />
					</div>
					<div class="full-width-box d-flex flex-column align-start justify-start">
						<div class="name">{{ configuration.name }}</div>
						<div class="sizes">
							<span v-for="(size, sizeIdx) in variant.sizes" :key="sizeIdx"
								><span class="size-amount">{{ size.amount }}x</span>{{ size.size }}</span
							>
						</div>
					</div>
				</div>
				<div class="bottom">
					<div
						v-for="(finish, finishIdx) in configuration.finishes"
						:key="finishIdx"
						class="d-flex pb-3 finish-row"
						@click="setMockupSide(finish)"
					>
						<img :src="$getFileUrlById(finish.fileName)" />
						<div class="d-flex flex-column">
							<div class="technique">
								{{ techniqueOptions[finish.technique] }}
								{{ sideOptions[finish.side] }}
								{{ positionOptions[finish.position] }}
							</div>
							<div
								class="d-flex mb-1 color-container"
								v-if="
									variant.finishes[finishIdx].type !== 'recommendation' ||
									(variant.finishes[finishIdx].type === 'recommendation' &&
										variant.finishes[finishIdx].colors[0].recommendedRgbColor)
								"
							>
								<div
									v-for="(color, id) in variant.finishes[finishIdx].colors"
									:key="id"
									class="circle"
									:style="{
										border: '1px solid lightgrey',
										background: color.recommendedRgbColor
											? color.recommendedRgbColor
											: color.substituteRgbColor
											? color.substituteRgbColor
											: color.hexColor,
									}"
								></div>
							</div>
							<div class="d-flex" v-if="variant.finishes[finishIdx].type === 'recommendation'">
								<small>{{ $getDictString('configurator_variants_text_finish_color_recommendet') }}</small>
							</div>
						</div>
					</div>
				</div>
				<div class="custom-product-description">
					{{ configuration.customProductDescription }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SVGFront from '../../configurator/mockups/SVGFront'
import SVGBack from '../../configurator/mockups/SVGBack'
import SVGLeft from '../../configurator/mockups/SVGLeft'
import SVGRight from '../../configurator/mockups/SVGRight'
import RemoveConfigurationWarningModal from './RemoveConfigurationWarningModal'

import { mapFields } from 'vuex-map-fields'

export default {
	name: 'ConfigurationCard',
	components: { SVGFront, SVGLeft, SVGRight, SVGBack, RemoveConfigurationWarningModal },
	props: {
		configuration: { type: Object, required: true },
		idx: { type: Number, required: false },
		cartOverlay: { type: Boolean, required: false },
		filesAlt: { type: Array, required: false },
		GET_FILE_BY_NAME_ALT: { type: Function, required: false },
	},
	computed: {
		...mapFields('ConfiguratorFiles', {
			filesDefault: 'files',
		}),
		files() {
			return this.filesAlt || this.filesDefault
		},
	},
	data() {
		return {
			visibleMockupSide: null,
			techniqueOptions: {
				print: 'Druck',
				stick: 'Stick',
				'inside-print': 'Innendruck',
				labels: 'Labels',
			},
			sideOptions: {
				front: 'Vorne',
				back: 'Hinten',
				'shoulder-sleeve': 'Ärmel',
				neck: 'Nacken-Innen',
				'label-sleeve': 'Ärmel',
				'label-hem': 'Saum',
				'label-loop-neck': 'Nacken-Innen',
				'label-hip': 'Hüfte',
				'label-front': 'Brust',
				'label-standard-neck': 'Nacken-Innen',
				left: 'Links',
				right: 'Rechts',
			},
			positionOptions: {
				'front-left': 'Links',
				'front-center': 'Mittig',
				'front-right': 'Rechts',
				'custom-front': 'Custom',
				'back-center': 'Mittig',
				'custom-back': 'Custom',
				'shoulder-sleeve-left-custom': 'Links',
				'shoulder-sleeve-right-custom': 'Rechts',
				'custom-left': 'Custom',
				'custom-right': 'Custom',
				'neck-center': 'Mittig',
				'label-sleeve-left': 'Links',
				'label-sleeve-right': 'Rechts',
				'label-hem-left': 'Links',
				'label-hem-right': 'Rechts',
				'label-standard-neck-center': 'Mittig',
				'label-hip-left': 'Links',
				'label-hip-right': 'Rechts',
				'label-front-left': 'Links',
				'label-front-right': 'Rechts',
				'label-loop-neck-center': 'Mittig',
			},
		}
	},
	mounted() {
		this.setMockupSide(this.configuration.finishes[0])
	},
	methods: {
		setMockupSide(finish) {
			this.visibleMockupSide = finish.side
			if (finish.side === 'shoulder-sleeve') {
				if (finish.position === 'shoulder-sleeve-left-custom') this.visibleMockupSide = 'left'
				else if (finish.position === 'shoulder-sleeve-right-custom') this.visibleMockupSide = 'right'
			}
		},
		getTotalAmount(variant) {
			let total = 0

			// for (let key in variants) {
			if (variant) {
				total = variant.sizes.reduce((acc, obj) => acc + obj.amount, total)
			}
			// }
			return total
		},
		editConfiguration(configuration) {
			this.$store.dispatch('Configurator/setConfigurationFromCustomerRequest', configuration.editId)
			this.$store.dispatch('ConfiguratorDisplay/setConfigOpenedByCartFlag', true)
			this.$store.dispatch('ConfiguratorDisplay/setStep', 'textile')
			this.$router.push({ name: 'configurator-config' })
		},
		removeConfiguration(configuration) {
			this.$store.dispatch('Configurator/removeConfigurationFromCustomerRequest', configuration.editId)
		},
	},
}
</script>

<style scoped lang="sass">
.product-wrapper
	position: relative
	width: 100%
	overflow: hidden
	display: inline-flex
	.overlay
		position: absolute
		background: rgba(255,255,255,0.95)
		text-transform: uppercase
		top: 0
		left: 0
		width: 100%
		height: 100%
		transition: all 200ms
		z-index: 19
		font-weight: 700
		font-size: 10px
		display: flex
		justify-content: center
		align-items: center
		letter-spacing: 0.02em
		opacity: 0
		&:hover
			opacity: 1
		.edit-btn
			display: inline-block
			background: black
			color: white
			padding: 8px 12px
			border-radius: 8px
			cursor: pointer
	.index
		position: absolute
		top: -25px
		left: 0
		opacity: 0.1
		z-index: 0
		font-weight: 700
		font-size: 86px
	.product-inner
		height: 100%
		padding: 20px 0
	.product-info
		width: 60%
		.top,.bottom
			width: 100%
		.top
			.amount
				width: 100%
				font-size: 21px
				font-weight: 700
			.full-width-box
				position: relative
				top: -8px
				width: 100%
				.name
					font-size: 14px
					font-weight: 700
					letter-spacing: -0.04em
				.colors
					.color-image
						display: inline-block
						position: relative
						left: -10px
						&:first-of-type
							left: 0
					.color-circle
						width: 20px
						height: 20px
						border-radius: 50%
						display: inline-block
						position: relative
						left: -10px

				.sizes
					font-size: 16px
					font-weight: 400
					color: rgba(0,0,0,0.2)
					margin-bottom: 5px
					position: relative
					left: -4px
					span
						padding: 0 2px
						.size-amount
							font-size: 11px
							position: relative
							left: 1px
							// left: -4px
							color: rgba(0,0,0,0.4)
		.bottom
			.finish-row
				// cursor: pointer
			img
				height: 50px
				width: 50px
				margin-right: 10px
				object-fit: contain
			.technique
				font-size: 12px
				font-weight: 400
				letter-spacing: -0.02em
				margin-bottom: 5px
			.circle
				display: inline-block
				width: 25px
				height: 25px
				border-radius: 50%
				position: relative
				z-index: 10
				left: 0
				&:nth-of-type(2)
					left: -10px
					z-index: 8
				&:nth-of-type(3)
					left: -20px
					z-index: 7
				&:nth-of-type(4)
					left: -30px
					z-index: 6
				&:nth-of-type(5)
					left: -40px
					z-index: 5
				&:nth-of-type(6)
					left: -50px
					z-index: 4
				&:nth-of-type(7)
					left: -60px
					z-index: 3
				&:nth-of-type(8)
					left: -70px
					z-index: 2
				&:nth-of-type(9)
					left: -80px
					z-index: 1
	.image
		width: 50%
		padding-left: 15px
		position: relative
		display: inline-flex
		justify-content: center
		align-items: center
		.svg-image
			width: 100%
		.variant-color
			font-size: 12px
			font-weight: 400
			letter-spacing: -0.02em
</style>
