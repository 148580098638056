<template>
	<div>
		<div class="delete-btn d-flex align-center justify-center" @click="showModal = true">
			<v-icon>mdi-close</v-icon>
		</div>

		<v-dialog v-model="showModal" width="500" transition="dialog-bottom-transition">
			<v-card id="remove-configuration-warning-modal">
				<v-toolbar dark color="black" elevation="0" tile class="toolbar">
					<v-toolbar-title>{{ $getDictString('configurator_cart_remove_configuration_heading') }}</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
				<div class="d-flex flex-wrap color-scroll-box align-content-start pa-5">
					{{ $getDictString('configurator_cart_remove_configuration_text') }}
				</div>

				<v-divider></v-divider>

				<v-card-actions>
					<v-btn color="primary" class="weakBtn" text @click="showModal = false">
						{{ $getDictString('configurator_cart_remove_configuration_cancel_button') }}
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" class="strongBtn" text @click="removeConfiguration">
						{{ $getDictString('configurator_cart_remove_configuration_accept_button') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'RemoveConfigurationWarningModal',
	props: {
		editId: { type: String, required: true },
	},
	data() {
		return {
			showModal: false,
		}
	},
	methods: {
		removeConfiguration() {
			this.$store.dispatch('Configurator/removeConfigurationFromCustomerRequest', this.editId)
			this.showModal = false
		},
	},
}
</script>

<style lang="sass" scoped>
.delete-btn
	position: absolute
	top: 1px
	right: 1px
	font-size: 14px
	text-transform: uppercase
	letter-spacing: 0.1em
	padding: 4px
	z-index: 10
	cursor: pointer
	padding: 6px 8px
	font-weight: 700
	i
		color: black
</style>

<style lang="sass">
#remove-configuration-warning-modal
	font-weight: 700
	.toolbar, .v-toolbar
		border-radius: 4px 4px 0 0 !important
		border: solid 1px transparent
		.v-toolbar__title
			font-size: 2em !important
	.strongBtn
		background: black
		color: white !important
		text-transform: none
		font-weight: 700
		font-size: 1em
		letter-spacing: -0.01em
		padding: 20px 20px
	.weakBtn
		background: white
		color: black !important
		text-transform: none
		font-weight: 700
		font-size: 1em
		letter-spacing: -0.01em
		padding: 20px 20px
		border: solid 2px black
</style>