var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-wrapper d-flex flex-column justify-start border-bottom"},[(_vm.cartOverlay)?_c('div',{staticClass:"overlay"},[_c('RemoveConfigurationWarningModal',{attrs:{"editId":_vm.configuration.editId}}),_c('div',{staticClass:"edit-btn d-flex align-center justify-center",on:{"click":function($event){return _vm.editConfiguration(_vm.configuration)}}},[_vm._v(" "+_vm._s(_vm.$getDictString('configurator_cart_button_edit_config'))+" ")])],1):_vm._e(),_c('div',{staticClass:"index d-flex align-center justify-center"},[_vm._v(_vm._s(_vm.idx + 1))]),_vm._l((_vm.configuration.variants),function(variant,id){return _c('div',{key:id,staticClass:"product-inner fill-height d-flex justify-start"},[_c('div',{staticClass:"image d-flex align-center flex-column justify-center",class:{ cap: _vm.configuration.ref.productType == 'cap' }},[(_vm.visibleMockupSide === 'front')?_c('SVGFront',{staticClass:"svg-image",attrs:{"mockupSide":"front","mainColor":variant.extractedHexColor,"secColor":variant.extractedHexColor,"allFinishes":_vm.configuration.finishes,"currentStep":"cart","mockupType":_vm.configuration.ref.productType,"hideBlockedAreas":true,"filesAlt":_vm.files}}):_vm._e(),(_vm.visibleMockupSide === 'back')?_c('SVGBack',{staticClass:"svg-image",attrs:{"mockupSide":"back","mainColor":variant.extractedHexColor,"secColor":variant.extractedHexColor,"allFinishes":_vm.configuration.finishes,"currentStep":"cart","mockupType":_vm.configuration.ref.productType,"filesAlt":_vm.files}}):_vm._e(),(
					_vm.visibleMockupSide === 'left' &&
					(_vm.configuration.finishes[0].position === 'shoulder-sleeve-left-custom' ||
						_vm.configuration.finishes[0].position === 'custom-left')
				)?_c('SVGLeft',{staticClass:"svg-image",attrs:{"mockupSide":"left","mainColor":variant.extractedHexColor,"secColor":variant.extractedHexColor,"allFinishes":_vm.configuration.finishes,"currentStep":"cart","mockupType":_vm.configuration.ref.productType,"filesAlt":_vm.files}}):_vm._e(),(
					_vm.visibleMockupSide === 'right' &&
					(_vm.configuration.finishes[0].position === 'shoulder-sleeve-right-custom' ||
						_vm.configuration.finishes[0].position === 'custom-right')
				)?_c('SVGRight',{staticClass:"svg-image",attrs:{"mockupSide":"right","mainColor":variant.extractedHexColor,"secColor":variant.extractedHexColor,"allFinishes":_vm.configuration.finishes,"currentStep":"cart","mockupType":_vm.configuration.ref.productType,"filesAlt":_vm.files}}):_vm._e(),_c('small',{staticClass:"variant-color text-center"},[_vm._v("Textilfarbe:"),_c('br'),_c('b',[_vm._v(_vm._s(variant.color))])])],1),_c('div',{staticClass:"product-info"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"amount d-flex align-center"},[_vm._v(" "+_vm._s(_vm.getTotalAmount(variant))),_c('img',{staticClass:"ml-1",attrs:{"src":require("@/assets/icons/cross-icon.png")}})]),_c('div',{staticClass:"full-width-box d-flex flex-column align-start justify-start"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.configuration.name))]),_c('div',{staticClass:"sizes"},_vm._l((variant.sizes),function(size,sizeIdx){return _c('span',{key:sizeIdx},[_c('span',{staticClass:"size-amount"},[_vm._v(_vm._s(size.amount)+"x")]),_vm._v(_vm._s(size.size))])}),0)])]),_c('div',{staticClass:"bottom"},_vm._l((_vm.configuration.finishes),function(finish,finishIdx){return _c('div',{key:finishIdx,staticClass:"d-flex pb-3 finish-row",on:{"click":function($event){return _vm.setMockupSide(finish)}}},[_c('img',{attrs:{"src":_vm.$getFileUrlById(finish.fileName)}}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"technique"},[_vm._v(" "+_vm._s(_vm.techniqueOptions[finish.technique])+" "+_vm._s(_vm.sideOptions[finish.side])+" "+_vm._s(_vm.positionOptions[finish.position])+" ")]),(
								variant.finishes[finishIdx].type !== 'recommendation' ||
								(variant.finishes[finishIdx].type === 'recommendation' &&
									variant.finishes[finishIdx].colors[0].recommendedRgbColor)
							)?_c('div',{staticClass:"d-flex mb-1 color-container"},_vm._l((variant.finishes[finishIdx].colors),function(color,id){return _c('div',{key:id,staticClass:"circle",style:({
									border: '1px solid lightgrey',
									background: color.recommendedRgbColor
										? color.recommendedRgbColor
										: color.substituteRgbColor
										? color.substituteRgbColor
										: color.hexColor,
								})})}),0):_vm._e(),(variant.finishes[finishIdx].type === 'recommendation')?_c('div',{staticClass:"d-flex"},[_c('small',[_vm._v(_vm._s(_vm.$getDictString('configurator_variants_text_finish_color_recommendet')))])]):_vm._e()])])}),0),_c('div',{staticClass:"custom-product-description"},[_vm._v(" "+_vm._s(_vm.configuration.customProductDescription)+" ")])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }