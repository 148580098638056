<template>
	<div class="text-center">
		<button v-if="newAddress" class="new-address" @click="dialog = true">Hinzufügen</button>
		<button v-if="!newAddress" @click="dialog = true" class="context-button">
			<v-icon> mdi-dots-vertical </v-icon>
		</button>
		<v-dialog v-model="dialog" width="auto" min-width="300" class="modal-wrapper">
			<v-card min-width="400" class="styled-modal">
				<div v-if="newAddress" class="header">Neue Adresse</div>
				<div v-if="!newAddress" class="header">Adresse bearbeiten</div>

				<v-row v-if="newAddress">
					<v-col cols="12" class="px-8 py-10">
						<v-form ref="new-address-form" lazy-validation>
							<v-text-field
								v-model="newCustomerAddress.name"
								name="name"
								label="Adresszeile 1"
								required
								:rules="nameRules"
								color="black"
								class="mt-2"
								hide-details="auto"
							></v-text-field>
							<v-text-field
								v-model="newCustomerAddress.name2"
								name="company"
								label="Addresszeile 2"
								color="black"
								class="mt-2"
								hide-details="auto"
							>
							</v-text-field>
							<v-text-field
								label="Straße und Hausnummer"
								name="street"
								required
								:rules="addressRules"
								color="black"
								hide-details="auto"
								v-model="newCustomerAddress.street"
							>
							</v-text-field>
							<div class="double-input-row">
								<v-text-field
									:label="$getDictString('configurator_cart_label_contact_postal_code')"
									name="postal-code"
									required
									:rules="postCodeRules"
									color="black"
									hide-details="auto"
									v-model="newCustomerAddress.zip"
								></v-text-field>
								<v-text-field
									label="Stadt"
									name="city"
									required
									:rules="cityRules"
									color="black"
									hide-details="auto"
									v-model="newCustomerAddress.city"
								>
								</v-text-field>
							</div>
							<v-select
								v-model="newCustomerAddress.country"
								:items="countries"
								:rules="countryRules"
								:label="$getDictString('configurator_cart_lable_contact_country')"
								required
								name="country"
								class="mt-5 mb-0"
								color="black"
							></v-select>
						</v-form>
					</v-col>
				</v-row>

				<v-row v-if="!newAddress">
					<v-col cols="12" class="px-8 py-10">
						<v-form ref="address-form" lazy-validation>
							<v-text-field
								v-model="customerAddress.name"
								name="name"
								label="Anschrift"
								required
								:rules="nameRules"
								color="black"
								class="mt-2"
								hide-details="auto"
							>
							</v-text-field>
							<v-text-field
								v-model="customerAddress.name2"
								name="company"
								:label="$getDictString('configurator_cart_label_contact_firstname')"
								color="black"
								class="mt-2"
								hide-details="auto"
							></v-text-field>
							<!-- <v-text-field
							v-model="customerAddress.name3"
							:label="$getDictString('configurator_cart_label_contact_lastname')"
							required
							color="black"
							class="mt-2"
							hide-details="auto"
						></v-text-field> -->
							<v-text-field
								label="Straße und Hausnummer"
								name="street"
								required
								:rules="addressRules"
								color="black"
								hide-details="auto"
								v-model="customerAddress.street"
							>
							</v-text-field>
							<div class="double-input-row">
								<v-text-field
									:label="$getDictString('configurator_cart_label_contact_postal_code')"
									name="postal-code"
									required
									:rules="postCodeRules"
									color="black"
									hide-details="auto"
									v-model="customerAddress.zip"
								></v-text-field>
								<v-text-field
									label="Stadt"
									name="city"
									required
									:rules="cityRules"
									color="black"
									hide-details="auto"
									v-model="customerAddress.city"
								>
								</v-text-field>
							</div>
							<v-select
								:items="countries"
								:rules="countryRules"
								:label="$getDictString('configurator_cart_lable_contact_country')"
								v-model="customerAddress.country"
								name="country"
								required
								class="mt-5 mb-0"
								color="black"
							></v-select>
						</v-form>
					</v-col>
				</v-row>

				<div class="address-modal-footer">
					<button class="left" @click="dialog = false">Abbrechen</button>
					<button class="middle">
						<span v-if="!newAddress" @click="deleteAddress(customerAddress._id)">Löschen </span>
					</button>
					<button v-if="!newAddress" @click="updateAddress()" class="right">Speichern</button>
					<button v-if="newAddress" @click="addNew()" class="right">Hinzufügen</button>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
	props: {
		newAddress: {
			type: Boolean,
			required: true,
		},
		customerAddress: {
			type: Object,
			required: false,
		},
	},
	computed: {
		...mapFields('Auth', {
			customer: 'customer',
		}),
	},
	data() {
		let dialog = false

		const newCustomerAddress = {
			name: null,
			name2: null,
			name3: null,
			street: null,
			zip: null,
			city: null,
			country: null,
			mainAddress: false,
		}

		return {
			dialog,
			newCustomerAddress,
			countries: [
				{ text: 'Deutschland', value: { shortCode: 'DE', name: 'Deutschland' } },
				{ text: 'Österreich', value: { shortCode: 'AT', name: 'Österreich' } },
				{ text: 'Schweiz', value: { shortCode: 'CH', name: 'Schweiz' } },
			],
			emailRules: [(v) => !!v || 'Email wird benötigt', (v) => /.+@.+/.test(v) || 'Die Email muss valide sein.'],
			nameRules: [(v) => !!v || 'Feld wird benötigt', (v) => (v && v.length > 2) || 'Das Feld muss mehr als 2 Buchstaben haben.'],
			companyRules: [(v) => (v && v.length > 2) || 'Die Firma muss mehr als 2 Buchstaben haben.'],
			addressRules: [
				(v) => !!v || 'Adresse wird benötigt',
				(v) => (v && v.length > 2) || 'Die Adresse muss mehr als 2 Buchstaben haben.',
			],
			cityRules: [(v) => !!v || 'Stadt wird benötigt', (v) => (v && v.length > 2) || 'Die Stadt muss mehr als 2 Buchstaben haben.'],
			countryRules: [(v) => !!v || 'Land wird benötigt.'],
			phoneRules: [(v) => !!v || 'Telefonnummer muss sein.'],
			postCodeRules: [(v) => !!v || 'PLZ muss angegeben werden.'],
			agbRules: [(v) => !!v || 'AGBs müssen akzeptiert werden.'],
			techniqueOptions: {
				print: 'Druck',
				stick: 'Stick',
				'inside-print': 'Innendruck',
				labels: 'Labels',
			},
		}
	},
	methods: {
		resetData() {
			this.newCustomerAddress = {
				name: null,
				name2: null,
				name3: null,
				street: null,
				zip: null,
				city: null,
				country: null,
				mainAddress: false,
			}
		},
		addNew() {
			const data = this.newCustomerAddress

			const valid = this.$refs['new-address-form'].validate()
			if (valid) {
				// add to existing user
				if (this.customer && this.customer._id) {
					this.$store.dispatch('Auth/newAddress', data).then(() => {
						this.$store.dispatch('Auth/fetchCustomerAddresses')
						this.dialog = false
					})
				} else {
					// add to new user, address is not yet saved in db
					this.$store.dispatch('Auth/newAddressLocal', data).then(() => {
						this.resetData()
						this.dialog = false
					})
				}
			}
		},
		updateAddress() {
			const data = this.customerAddress

			const valid = this.$refs['address-form'].validate()
			if (valid) {
				if (this.customer && this.customer._id) {
					this.$store.dispatch('Auth/updateAddress', data).then(() => {
						this.$store.dispatch('Auth/fetchCustomerAddresses')
						this.dialog = false
					})
				} else {
					this.dialog = false
				}
			}
		},
		deleteAddress(id) {
			if (this.customer && this.customer._id) {
				this.$store.dispatch('Auth/deleteAddress', id).then(() => {
					this.$store.dispatch('Auth/fetchCustomerAddresses')
					this.dialog = false
				})
			} else {
				// delete address locally
				this.$store.dispatch('Auth/deleteAddressLocal', id).then(() => {
					this.resetData()
					this.dialog = false
				})
			}
		},
	},
}
</script>

<style scoped lang="sass">
.new-address
  padding: 4px 10px
  color: white
  font-weight: 700
  background: black
  border: solid 1px black
.modal-wrapper
  border-radius: none
.styled-modal
  border: solid 10px black

  border-adius: none   !important
  .header
    display: inline-flex
    justify-content: center
    align-items: center
    width: 100%
    height: 60px
    color: black
    font-size: 30px
    font-weight: 700
    border-bottom: solid 3px black

    border-adius: none !important
  input
    padding: 20px
    font-weight: 700
.address-modal-footer
  display: flex
  height: 60px
  border-top: solid 4px black
  button
    display: inline-flex
    justify-content: center
    align-items: center
    width: 33.33%
    height: 100%
    font-size: 17px
    font-weight: 700
  .middle
    border-right: solid 4px black
    border-left: solid 4px black
  .right
    width: 33.33%
</style>
