<template>
	<div id="cart-checkout">
		<div class="border-box">
			<v-row class="pa-0 ma-0">
				<v-col cols="12" class="ma-0 px-5 py-4 border-bottom">
					<BigLabel :text="$getDictString('configurator_cart_heading_shipping')" />
				</v-col>
			</v-row>
			<v-row class="pa-0 ma-0">
				<v-col cols="12" class="ma-0 px-6 py-0 border-bottom">
					<v-radio-group v-model="shippingType" required hide-details="auto" class="mt-0">
						<v-radio v-for="(type, idx) in shippingTypes" :key="idx" :value="type.value"
							><template v-slot:label>
								<div class="shipping-option">
									<div class="text-black">
										<strong>{{ type.label }} </strong>
									</div>
									<small>{{ type.description }}</small>
								</div>
							</template></v-radio
						>
					</v-radio-group>
				</v-col>
			</v-row>
		</div>
		<div class="border-box">
			<v-row class="pa-0 ma-0">
				<v-col cols="12" class="ma-0 px-5 py-4 border-bottom"
					><BigLabel :text="$getDictString('configurator_cart_heading_comment')"
				/></v-col>
			</v-row>
			<v-row class="pa-0 ma-0">
				<v-col cols="12" class="ma-0 mt-0 p-0">
					<v-textarea
						label=""
						v-model="comment"
						auto-grow
						class="comment-box mb-0"
						rows="3"
						border="none"
						:placeholder="$getDictString('configurator_cart_placeholder_comment')"
						border-radius="0"
						hide-details="true"
					></v-textarea>
				</v-col>
			</v-row>
		</div>
		<div class="border-box">
			<v-form ref="legal-form" lazy-validation>
				<v-row class="px-0 pl-5 ma-0">
					<v-col cols="12" class="mb-0 px-1 py-0">
						<v-checkbox
							class="p-1 checkbox-agb"
							v-model="agbAccepted"
							:rules="agbRules"
							required
							color="black"
							:label="$getDictString('configurator_cart_label_agb')"
							hide-details="true"
						></v-checkbox>
					</v-col>
				</v-row>
				<v-row class="px-1 pl-5 ma-0">
					<v-col cols="12" class="mb-1 px-1 py-0">
						<v-checkbox
							class="p-1 pr-6 text-s checkbox-agb"
							v-model="rightsDisclaimer"
							:rules="rightsDisclaimerRules"
							required
							color="black"
							:label="$getDictString('configurator_cart_label_rights_disclaimer')"
							hide-details="true"
						></v-checkbox>
					</v-col>
				</v-row>
			</v-form>
			<div class="bottom-bar-button">
				<BlackButton :label="$getDictString('configurator_cart_button_send')" @click.native="sendCustomerRequest" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import { EventBus } from '../../../utils/eventbus'

import BlackButton from '../../configurator/components/BlackButton'
import BigLabel from '../../configurator/components/BigLabel'

export default {
	name: 'Checkout',
	components: { BlackButton, BigLabel },
	computed: {
		...mapFields('ConfiguratorRequest', {
			shippingType: 'shippingType',
			comment: 'comment',
			agbAccepted: 'agbAccepted',
			rightsDisclaimer: 'rightsDisclaimer',
		}),
		...mapFields('Configurator', {
			requestConfigurations: 'customerRequest.configurations',
		}),
		shippingTypes() {
			return [
				{
					value: 'standard',
					label: this.$getDictString('configurator_cart_label_shipping_standard'),
					discount: null,
					description: this.$getDictString('configurator_cart_text_shipping_standard'),
				},
				{
					value: 'express',
					label: this.$getDictString('configurator_cart_label_shipping_express'),
					discount: this.$getDictString('configurator_cart_text_shipping_express_discount'),
					description: this.$getDictString('configurator_cart_text_shipping_express'),
				},
				{
					value: 'slow',
					label: this.$getDictString('configurator_cart_label_shipping_slow'),
					discount: this.$getDictString('configurator_cart_text_shipping_slow_discount'),
					description: this.$getDictString('configurator_cart_text_shipping_slow'),
				},
			]
		},
	},
	data() {
		return {
			agbRules: [(v) => !!v || 'AGBs müssen akzeptiert werden.'],
			rightsDisclaimerRules: [(v) => !!v || 'Du musst bestätigen, dass keine Rechte verletzt werden.'],
		}
	},
	methods: {
		sendCustomerRequest() {
			EventBus.$emit('CustomerInfo/getFormValidation', (result) => {
				const customerFormValid = result
				const legalFormValid = this.$refs['legal-form'].validate()

				console.log('customerFormValid: ', customerFormValid)
				console.log('legalFormValid: ', legalFormValid)

				if (customerFormValid && legalFormValid && this.requestConfigurations && this.requestConfigurations.length > 0) {
					this.$store.dispatch('ConfiguratorRequest/sendCustomerRequest').then(() => {
						this.$store.dispatch('Configurator/resetEverything')
						this.$router.push({ name: 'configurator-success' })
					})
				}
			})
		},
	},
}
</script>

<style lang="sass">
#cart-checkout
	.comment-box
		textarea
			padding: 0 20px !important
</style>

<style lang="sass" scoped>
.border-box
	border: 1px solid #DDDDDD
	position: relative
	background: white
	width: 100%
	overflow: hidden
	position: relative
	top: 0
	margin-bottom: 20px
	.bottom-bar-button
		bottom: 0
		left: 0
		width: 100%
		position: relative
		button
			width: 100%
			border-radius: 0

.border-bottom
	border-bottom: 1px solid rgba(112, 112, 112, 0.1)

.checkbox-agb
	font-weight: 700
	color: black !important
	font-size: 12px !important
	label
		color: red
		font-size: 10px

.shipping-option
	padding: 8px 0
</style>
