<template>
	<button x-large dark right class="nextButton">
		<img :src="iconSrc" v-if="iconSrc" />
		{{ label }}
	</button>
</template>

<style scoped lang="sass">
.nextButton
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    border-radius: 4px
    font-size: 22px
    font-weight: 700
    letter-spacing: -0.02em
    padding: 14px 35px
    img
        margin-right: 15px
</style>

<script>
export default {
	name: 'BlackButton',
	props: {
		label: { type: String },
		iconSrc: { type: String },
	},
}
</script>