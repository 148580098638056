<template>
	<div>
		<div v-if="requestConfigurations.length > 0" class="add-article-btn d-flex align-center justify-center" @click="addConfiguration">
			<BigLabel :text="$getDictString('configurator_cart_button_add_config')" />
		</div>
		<div class="border-box">
			<v-row class="pa-0 ma-0 fill-height d-flex text-center justify-center items-center" v-if="requestConfigurations.length === 0">
				<v-col cols="12" class="ma-0 px-5 py-2 d-flex justify-center items-center text-center">
					<BlackButton :label="$getDictString('configurator_cart_button_start_now')" @click.native="addConfiguration" />
				</v-col>
			</v-row>
			<v-container class="scroll-box products-box pa-0 ma-0" v-if="renderConfigurationsCards">
				<v-row class="pa-0 ma-0" v-for="(configuration, idx) in requestConfigurations" :key="idx">
					<v-col cols="12" class="pa-0">
						<ConfigurationCard :configuration="configuration" :idx="idx" :cartOverlay="true" />
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import BlackButton from '../../configurator/components/BlackButton'
import BigLabel from '../../configurator/components/BigLabel'
import ConfigurationCard from '../partials/ConfigurationCard'

export default {
	name: 'ConfigurationsList',
	components: { BlackButton, BigLabel, ConfigurationCard },
	computed: {
		...mapFields('Configurator', {
			requestConfigurations: 'customerRequest.configurations',
		}),
		...mapFields('ConfiguratorDisplay', {
			currentStep: 'currentStep',
		}),
	},
	data() {
		return {
			renderConfigurationsCards: true,
		}
	},
	watch: {
		requestConfigurations() {
			this.renderConfigurationsCards = false
			setTimeout(() => {
				this.renderConfigurationsCards = true
			}, 10)
		},
	},
	methods: {
		addConfiguration() {
			this.currentStep = 'textile'
			this.$store.dispatch('Configurator/resetConfiguration')
			this.$router.push({ name: 'configurator-config' })
		},
	},
}
</script>

<style lang="sass" scoped>
.add-article-btn
    position: absolute
    bottom: 0
    left: 0
    right: 4%
    width: 100%
    height: 60px
    text-align: center
    border: 1px solid #DDDDDD
    cursor: pointer
    z-index: 20
    background: white
    transition: all 200ms ease-in
    &:hover
        filter: invert(1)
.border-box
    border: 1px solid #DDDDDD
    position: relative
    background: white
    width: 100%
    overflow: hidden
    position: relative
    top: 0

    .scroll-box
        position: relative
        height: calc(100vh - 190px)
        overflow-y: scroll
        margin-bottom: 80px
        &.products-box
            margin-bottom: 20px !important
</style>
