<template>
	<div id="emailValidationWrapper">
		<div class="border-box popup-window">
			<v-row class="pa-0 ma-0">
				<v-col cols="12" class="ma-0 px-5 py-5 border-bottom"> <BigLabel text="Bevor es weitergeht" /> </v-col>
			</v-row>

			<div v-if="!validated" class="px-5 py-5">
				Gib bitte deine E-Mail Adresse ein um mit dem Abschluss deiner Bestellung fortzufahren.
			</div>
			<div v-if="validated && valid" class="px-5 py-5">
				Bitte gebe den Zugangscode den wir dir per Email geschickt haben hier ein.
			</div>

			<div class="mt-4 mb-5" v-if="!validated">
				<v-text-field
					v-model="customerEmail"
					:rules="emailRules"
					:label="$getDictString('configurator_cart_label_contact_email')"
					required
					color="black"
					class="checkout-input"
					hide-details="auto"
				></v-text-field>
			</div>
			<div class="mt-4 mb-5" v-if="validated && valid">
				<v-text-field
					v-model="loginCode"
					label="Code aus E-Mail"
					required
					color="black"
					class="checkout-input"
					hide-details="auto"
				></v-text-field>
			</div>
			<div class="mt-4 mb-5 px-5 text-red" v-if="validated && !valid">Mit dieser E-Mail Adresse gibt es noch keinen Account</div>
			<div class="bottom-bar-button">
				<BlackButton label="Weiter" v-if="!validated" @click.native="validate" />
				<BlackButton label="Weiter" v-if="validated && valid" @click.native="validateCode" />
				<BlackButton label="Weiter ohne Login" v-if="validated && !valid" @click.native="continueWithoutLogin" />
			</div>
		</div>
	</div>
</template>

<script>
import BigLabel from '../../configurator/components/BigLabel'
import BlackButton from '../../configurator/components/BlackButton'

export default {
	name: 'EmailValidation',
	components: { BigLabel, BlackButton },
	data() {
		return {
			customerEmail: null,
			loginCode: null,
			emailRules: [(v) => !!v || 'Email wird benötigt', (v) => /.+@.+/.test(v) || 'Die Email muss valide sein.'],
			validated: false,
			valid: false,
		}
	},
	methods: {
		validate() {
			if (this.customerEmail) {
				this.$store
					.dispatch('Auth/requestLoginCode', this.customerEmail)
					.then((res) => {
						if (res && res.success) {
							this.validated = true
							this.valid = true
						} else {
							this.validated = true
							this.valid = false
							this.$store.dispatch('Auth/continueAsGuest', this.customerEmail)
						}
					})
					.catch(() => {
						this.validated = true
						this.valid = false
					})
			}
		},
		validateCode() {
			if (this.loginCode) {
				this.$store
					.dispatch('Auth/validateLoginCode', this.loginCode)
					.then(() => this.$store.dispatch('Auth/getCustomerData'))
					.then(() => this.$store.dispatch('Auth/fetchCustomerAddresses'))
					.then(() => this.$store.dispatch('ConfiguratorRequest/insertCustomerData'))
					.catch((err) => {
						console.log(err)
					})
			}
		},
		continueWithoutLogin() {
			this.$store.dispatch('Auth/continueAsGuest', this.customerEmail)
		},
	},
}
</script>

<style scoped lang="sass">
#emailValidationWrapper
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: 99
	background: rgba(255,255,255,0.8)
	display: flex
	justify-content: center
	align-items: center
	backdrop-filter: blur(4px)
	font-size: 18px
	.popup-window
		width: 400px
.border-box
	border: 1px solid #DDDDDD
	position: relative
	background: white
	width: 100%
	overflow: hidden
	position: relative
	top: -1px

	.bottom-bar-button
		bottom: 0
		left: 0
		width: 100%
		position: relative
		button
			width: 100%
			border-radius: 0

.text-red
	color: red

.border-bottom
	border-bottom: 1px solid rgba(112, 112, 112, 0.1)

.checkout-input
	letter-spacing: -0.05em
	font-weight: 700
	font-size: 16px
	&.phone
		input
			padding-bottom: 20px !important

.v-input
	input
		padding-left: 20px
</style>
