<template>
	<div>
		<div class="border-box mb-5">
			<v-row class="pa-0 ma-0">
				<v-col cols="12" class="ma-0 px-5 py-5 border-bottom">
					<BigLabel :text="$getDictString('configurator_cart_heading_contact')" />
				</v-col>
			</v-row>
			<v-form ref="customer-form" lazy-validation>
				<v-text-field
					v-model="customerCompany"
					:label="$getDictString('configurator_cart_label_contact_company')"
					color="black"
					class="mt-2 checkout-input"
					variant="solo"
					hide-details="auto"
				></v-text-field>

				<v-row>
					<v-col cols="6" class="pr-0">
						<v-text-field
							v-model="customerFirstname"
							:rules="nameRules"
							:label="$getDictString('configurator_cart_label_contact_firstname')"
							required
							color="black"
							class="mt-1 checkout-input"
							variant="solo"
							hide-details="auto"
						></v-text-field>
					</v-col>
					<v-col cols="6" class="pl-0">
						<v-text-field
							v-model="customerLastname"
							:rules="nameRules"
							:label="$getDictString('configurator_cart_label_contact_lastname')"
							required
							color="black"
							class="mt-1 checkout-input"
							hide-details="auto"
						></v-text-field>
					</v-col>
				</v-row>
				<v-text-field
					v-model="customerEmail"
					:rules="emailRules"
					:label="$getDictString('configurator_cart_label_contact_email')"
					required
					color="black"
					class="checkout-input"
					hide-details="auto"
					:disabled="true"
				></v-text-field>
				<v-text-field
					v-model="customerPhone"
					:rules="phoneRules"
					:label="$getDictString('configurator_cart_label_contact_phone')"
					required
					height="50"
					color="black"
					class="mb-0 checkout-input phone"
					hide-details="auto"
				></v-text-field>
			</v-form>
		</div>
		<div class="border-box mt-5">
			<v-row class="pa-0 ma-0">
				<v-col cols="9" class="ma-0 px-5 py-4 border-bottom">
					<BigLabel text="Lieferadresse" />
				</v-col>
				<v-col cols="3" class="ma-0 px-5 pr-7 py-0 address-window-container border-bottom">
					<AddressWindow :newAddress="true" />
				</v-col>
			</v-row>
			<div v-for="(address, index) in customerAddresses" class="radio-row" :key="index" @click="selectShippingAddress(address._id)">
				<input type="radio" :checked="shippingAddress == address._id" />
				<span class="details"
					>{{ address.name }}, {{ address.street }}, {{ address.zip }}, {{ address.city }}, {{ address.country.name }}</span
				>
				<AddressWindow class="context-button" :customerAddress="address" :newAddress="false" />
			</div>
		</div>
		<div class="details-invoice">
			<div
				class="switch-wrap"
				:class="{ active: invoiceSwitch }"
				@click=";(invoiceSwitch = !invoiceSwitch), selectInvoiceAddress(false)"
			>
				<div class="switch"></div>
			</div>
			<span :class="{ active: invoiceSwitch }">Abweichende Rechnungsadresse</span>
		</div>
		<div class="border-box my-5" v-if="invoiceSwitch">
			<v-row class="pa-0 ma-0">
				<v-col cols="9" class="ma-0 px-5 py-5 border-bottom">
					<BigLabel text="Rechnungsadresse" />
				</v-col>
				<v-col cols="3" class="ma-0 px-5 pr-7 py-0 address-window-container border-bottom">
					<AddressWindow :newAddress="true" />
				</v-col>
			</v-row>
			<div v-for="(a, index) in customerAddresses" class="radio-row" :key="index">
				<input type="radio" @click="selectInvoiceAddress(a._id)" :checked="invoiceAddress && invoiceAddress == a._id" />
				<span>{{ a.name }}, {{ a.street }}, {{ a.zip }}, {{ a.city }}</span>
				<button class="context-button">
					<v-icon> mdi-dots-vertical </v-icon>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import { EventBus } from '../../../utils/eventbus'

import BigLabel from '../../configurator/components/BigLabel'
import AddressWindow from '../partials/AddressWindow'

export default {
	name: 'CustomerInfo',
	components: { BigLabel, AddressWindow },
	computed: {
		...mapFields('ConfiguratorRequest', {
			customerCompany: 'customer.company',
			customerFirstname: 'customer.firstname',
			customerLastname: 'customer.lastname',
			customerEmail: 'customer.email',
			customerPhone: 'customer.phone',
			shippingAddress: 'shippingAddress',
			invoiceAddress: 'invoiceAddress',
		}),
		...mapFields('Auth', {
			customerAddresses: 'customerAddresses',
		}),
	},
	data() {
		return {
			invoiceSwitch: false,
			emailRules: [(v) => !!v || 'Email wird benötigt', (v) => /.+@.+/.test(v) || 'Die Email muss valide sein.'],
			nameRules: [(v) => !!v || 'Name wird benötigt', (v) => (v && v.length > 2) || 'Der Name muss mehr als 2 Buchstaben haben.'],
			companyRules: [(v) => (v && v.length > 2) || 'Die Firma muss mehr als 2 Buchstaben haben.'],
			phoneRules: [(v) => !!v || 'Telefonnummer muss sein.'],
		}
	},
	created() {
		const that = this
		EventBus.$on('CustomerInfo/getFormValidation', (cb) => {
			cb(that.$refs['customer-form'].validate() && !!that.shippingAddress)
		})
	},
	methods: {
		selectShippingAddress(id) {
			this.$store.dispatch('ConfiguratorRequest/setShippingAddress', id)
		},
		selectInvoiceAddress(id) {
			this.$store.dispatch('ConfiguratorRequest/setInvoiceAddress', id)
		},
	},
}
</script>

<style scoped lang="sass">
.border-box
	border: 1px solid #DDDDDD
	position: relative
	background: white
	width: 100%
	overflow: hidden
	position: relative
	top: -1px

.border-bottom
	border-bottom: 1px solid rgba(112, 112, 112, 0.1)

.checkout-input
	letter-spacing: -0.05em
	font-weight: 700
	font-size: 14px
	&.phone
		input
			padding-bottom: 20px !important

.radio-row
	height: 60px
	width: 100%
	border-top: solid 1px rgb(220,220,220)
	display: flex
	justify-content: flex-start
	align-items: center
	position: relative
	input
		padding: 0 20px
		display: inline-flex
		margin: 0 10px 0 20px
	span
		justify-content: start
		align-items: center
		overflow: hidden
		font-weight: 700
		text-overflow: ellipsis
		white-space: nowrap
		width: 65%
		margin-bottom: 2px
	.context-button
		position: absolute
		right: 20px
		height: 100%
		display: inline-flex
		justify-content: center
		align-items: center
		color: black

.address-window-container
	display: inline-flex
	justify-content: flex-end
	align-items: center
	button
		color: wh

.details-invoice
	width: 100%
	height: 30px
	padding: 5px
	display: flex
	justify-content: center
	align-items: center
	color: rgb(200,200,200)
	span
		padding: 0 5px
		&.active
			color: rgb(40,40,40)
	.switch-wrap
		width: 24px
		height: 12px
		margin: 0 3px
		background: rgb(210,210,210)
		border-radius: 10%
		cursor: pointer
		&:hover
			background: rgb(200,200,200)
		.switch
			width: 12px
			height: 12px
			background: rgb(100,100,100)
		&.active
			background: rgb(180,180,180)
			.switch
				transform: translateX(12px)
				width: 12px
				height: 12px
				background: rgb(23,23,23)

.v-input
	input
		padding-left: 20px
</style>
