<template>
	<v-app>
		<v-main id="overview-wrapper">
			<v-sheet color="transparent" height="60px">
				<TopBar :showOverviewBtn="false" />
			</v-sheet>
			<v-sheet color="transparent" class="pad-top" height="calc(100vh - 60px)">
				<v-row
					align="center"
					class="d-flex h-100 justify-around items-center flex-row px-4"
					no-gutters
					v-if="loading || productsLoading"
				>
					<v-progress-circular indeterminate></v-progress-circular>
				</v-row>
				<v-row align="center" class="d-flex h-100 justify-around items-center flex-row px-4" no-gutters v-else>
					<v-col class="content-block left">
						<ConfigurationsList />
					</v-col>
					<v-col class="content-block center">
						<EmailValidation v-if="!customerIsLoggedIn && !continueAsGuest && !requestCreatedByAdmin" />
						<CustomerInfo v-if="customerIsLoggedIn || continueAsGuest || requestCreatedByAdmin" />
					</v-col>
					<v-col class="content-block right">
						<Checkout v-if="customerIsLoggedIn || continueAsGuest || requestCreatedByAdmin" />
					</v-col>
				</v-row>
			</v-sheet>
		</v-main>
	</v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import TopBar from '../configurator/partials/TopBar'
import ConfigurationsList from './components/ConfigurationsList.vue'
import CustomerInfo from './components/CustomerInfo.vue'
import Checkout from './components/Checkout.vue'
import EmailValidation from './components/EmailValidation.vue'

export default {
	name: 'CartOverview',
	components: { TopBar, ConfigurationsList, CustomerInfo, Checkout, EmailValidation },
	computed: {
		...mapGetters('Auth', {
			customerIsLoggedIn: 'customerIsLoggedIn',
		}),
		...mapFields('Auth', {
			continueAsGuest: 'continueAsGuest',
		}),
		...mapFields('ConfiguratorDisplay', {
			currentStep: 'currentStep',
		}),
		...mapFields('InternalProducts', {
			productsLoading: 'loading',
		}),
		...mapFields('ConfiguratorRequest', {
			requestCreatedByAdmin: 'requestCreatedByAdmin',
			customerEmail: 'customer.email',
		}),
	},
	data() {
		return {
			loading: false,
		}
	},
	created() {
		this.loading = true
		this.$store.dispatch('InternalProducts/fetchProducts')
		this.$store
			.dispatch('Dictionary/fetchReadableByModule', 'configurator')
			.then(() => this.$store.dispatch('Auth/fetchCustomerAddresses'))
			.then(() => {
				if (this.customerIsLoggedIn && !this.customerEmail) {
					this.$store.dispatch('ConfiguratorRequest/insertCustomerData')
				}
			})
			.finally(() => (this.loading = false))
	},
	mounted() {
		this.currentStep = 'cart-overview'
	},
	methods: {},
}
</script>

<style scoped lang="sass">
#overview-wrapper
	background-image: radial-gradient(rgba(0, 0, 0, 0.15) 10%, #fff 9%)
	background-position: 0 0
	background-size: 20px 20px
	background-repeat: repeat
	@media screen and (min-width: 1200px)
		padding: 0 80px !important
	@media screen and (min-width: 1400px)
		padding: 0 180px !important
	@media screen and (min-width: 1700px)
		padding: 0 200px !important
.pad-top
	padding-top: 20px

.content-block
	box-sizing: border-box
	height: calc(100vh - 130px)
	width: 28%
	margin-left: 10px
	margin-right: 10px
	overflow-y: scroll
	&.right
		display: flex
		margin-top: 0
		margin-bottom: 0
		flex-direction: column
		justify-content: space-between
		overflow: hidden
	&.center
		border-top: 1px solid #DDDDDD
	&.left
		padding-bottom: 59px !important
		height: calc(100vh - 130px)
		overflow: hidden
		position: relative
</style>
